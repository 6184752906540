import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { get, isNil, first, pick, noop, isEmpty, omit } from 'lodash';
import update from 'immutability-helper';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './Form.css';
import LanguageSelect from '../Field/LanguageSelect';
import General from './General';
import Actions from './Actions';
import DisabledFields from './DisabledFields';
import workguideActions from '../../../../../actions/Actions';
import ComponentIdMap from '../../../../General/ComponentIdMap';
import NodeIdMap from '../../General/NodeIdMap';
import JmesInputHelp from '../../../../General/JmesInputHelp';
import NodeValidationDefinition from '../../../../../lib/Validation/NodeValidationDefinition';
import { getValidator } from '../../../../../../../globals';

class WorkflowTreeFormEditNode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onTranslatableValueChange = this.onTranslatableValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillUnmount() {
    const { workguideActions } = this.props;

    workguideActions.workflowTreeEditNodeFormReset();
  }

  /**
   * Set the language to use for multilanguage config values
   *
   * @param   {String}  key    Form element id
   * @param   {String}  value  Selected language
   *
   * @return  void
   */
  onLanguageChange(key, value) {
    this.setState({ language: first(value) });
  }

  /**
   * Set the given value for the given key in data
   *
   * @param   {String}  key    Key in data
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onValueChange(key, value) {
    const { workguideActions } = this.props;

    workguideActions.workflowTreeEditNodeFormSetValue(key, value);
  }

  /**
   * Set the given value for the current selected language on the given key in data
   *
   * @param   {String}  key    Key in data
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onTranslatableValueChange(key, value) {
    const { language } = this.state;
    const {
      form,
      workguideActions
    } = this.props;

    const current = get(form, `data.${key}`, {});
    const updated = isNil(value)
      ? update(current, { $unset: [language] })
      : update(current, { [language]: { $set: value } });

    workguideActions.workflowTreeEditNodeFormSetValue(key, updated);
  }

  /**
   * Submit the form
   *
   * @return  void
   */
  onSubmit() {
    const {
      form,
      onSubmit,
      workguideActions
    } = this.props;

    const data = get(form, 'data', {});
    workguideActions.workflowTreeEditNodeFormSaveRequest({ data });
    onSubmit({ data });
  }

  /**
   * Cancel edit and reset the form
   *
   * @return  void
   */
  onCancel() {
    const {
      onCancel,
      workguideActions
    } = this.props;

    workguideActions.workflowTreeEditNodeFormReset();
    onCancel();
  }

  /**
   * Validate current form data
   *
   * @return  {Object} result Validation result
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();

    return validator.validate(NodeValidationDefinition, get(form, 'data', {}));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { language } = this.state;
    const {
      consultants,
      form,
      languages,
      workguide
    } = this.props;

    const validations = this.validate();

    return (
      <div className="workflow-tree-form-edit-node">
        <LanguageSelect
          languages={languages}
          onChange={this.onLanguageChange}
          onTranslatableValueChange={this.onTranslatableValueChange}
          value={[language]}
          validations={pick(validations, ['title', 'description', 'comment'])}
        />

        <div style={{ paddingTop: '2em' }} />

        <ComponentIdMap components={get(workguide, 'components', [])} />

        <NodeIdMap nodes={get(workguide, 'workflow.tree.nodes', [])} />

        <JmesInputHelp />

        <div style={{ paddingTop: '2em' }} />

        <General
          consultants={consultants}
          form={form}
          language={language}
          onValueChange={this.onValueChange}
          onTranslatableValueChange={this.onTranslatableValueChange}
          validations={validations}
        />

        <Actions
          form={form}
          language={language}
          onValueChange={this.onValueChange}
          onTranslatableValueChange={this.onTranslatableValueChange}
          validations={validations}
        />

        <DisabledFields
          form={form}
          language={language}
          onValueChange={this.onValueChange}
          onTranslatableValueChange={this.onTranslatableValueChange}
          workguide={workguide}
          validations={validations}
        />

        <div className="workflow-tree-form-edit-node--buttons">
          <Button
            bsStyle="primary"
            disabled={!isEmpty(validations)}
            onClick={this.onSubmit}
          >
            <FormattedMessage id="Workguide.Workflow.Form.Button.Submit" />
          </Button>

          <div style={{ paddingRight: '1em' }} />

          <Button
            onClick={this.onCancel}
            outline
          >
            <FormattedMessage id="Workguide.Workflow.Form.Button.Cancel" />
          </Button>
        </div>
      </div>
    );
  }
}

WorkflowTreeFormEditNode.propTypes = {
  consultants: PropTypes.array,
  form: PropTypes.object,
  language: PropTypes.string,
  languages: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  workguide: PropTypes.object.isRequired,
  workguideActions: PropTypes.object.isRequired
};

WorkflowTreeFormEditNode.defaultProps = {
  consultants: [],
  form: {},
  language: 'de',
  languages: ['de', 'fr', 'en', 'it'],
  onCancel: noop,
  onSubmit: noop
};

function mapStateToProps(state) {
  return {
    form: state.workguide.workflowTreeEditNodeForm,
    workguide: state.workguide.workguide
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workguideActions: bindActionCreators(workguideActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowTreeFormEditNode
);
