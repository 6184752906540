import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { has, get, isNil, size, last, isEqual, isEmpty, noop, flow } from 'lodash';
import { v4 } from 'uuid';
import update from 'immutability-helper';
import cl from 'classnames';

import './Tree.css';
import Board from '../../components/Workflow/Tree/Board/Board';
import Node from '../../components/Workflow/Tree/Board/Node';
import * as workguideLegacyActions from '../../../../actions/WorkguideActions';
import workguideActions from '../../actions/Actions';
import {
  WORKFLOW_TREE_NODE,
  WORKFLOW_TREE_START_NODE
} from '../../constants';
import NodeValidationDefinition from '../../lib/Validation/NodeValidationDefinition';
import { getValidator } from '../../../../globals';
import WorkflowTreeNodes from '../../lib/Workflow/Nodes';
import ConnectionValidationDefinition from '../../lib/Validation/ConnectionValidationDefinition';

const requiredCodeGroups = [
  'workguideStatus',
  'workguideApprovalStatus'
];

class WorkflowTree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      positions: {
        start: { y: '50%' }
      },
      connecting: false,
      connectionSource: undefined,
      rearranging: false
    };

    this.nodes = WorkflowTreeNodes({ nodes: get(props, 'workguide.workflow.nodes', []) });

    this.onNodeAdd = this.onNodeAdd.bind(this);
    this.onNodeChange = this.onNodeChange.bind(this);
    this.onNodeDrop = this.onNodeDrop.bind(this);
    this.onNodeDuplicate = this.onNodeDuplicate.bind(this);
    this.onNodeRemove = this.onNodeRemove.bind(this);
    this.onConnectStart = this.onConnectStart.bind(this);
    this.onConnectEnd = this.onConnectEnd.bind(this);
    this.onConnectionChange = this.onConnectionChange.bind(this);
    this.onConnectionRemove = this.onConnectionRemove.bind(this);
    this.onRearrange = this.onRearrange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  /**
   * Load necessary data if not already done and add the start node if it does not exist
   *
   * @return  void
   */
  componentDidMount() {
    const {
      codeGroups,
      codes,
      consultants,
      workguide,
      workguideActions,
      workguideLegacyActions
    } = this.props;

    requiredCodeGroups
      .filter((group) => !has(codes, group))
      .forEach((group) => workguideLegacyActions.workguideCodesRequest(group));

    if (size(consultants) === 0) {
      workguideLegacyActions.getConsultantsRequest();
    }

    if (size(codeGroups) === 0) {
      workguideLegacyActions.getCodeGroupsRequest();
    }

    // Add start node if nodes are empty (no current config)
    if (this.nodes.count() === 0) {
      const node = {
        id: 'start',
        type: WORKFLOW_TREE_START_NODE,
        title: {
          de: 'Start',
          fr: 'Start'
        }
      };

      this.nodes.add(node);
    }

    workguideActions.workflowTreeConfigInitRequest({
      data: {
        ...get(workguide, 'workflow', {}),
        nodes: this.nodes.getNodes()
      }
    });
  }

  /**
   * Rearrange nodes after init
   *
   * @param   {Object}  prevProps  Prev props
   *
   * @return  void
   */
  componentDidUpdate(prevProps) {
    const initialized = get(this, 'props.form.initialized', false);
    const prevInitialized = get(prevProps, 'form.initialized', false);

    if (initialized && !prevInitialized) {
      this.onRearrange();
    }
  }

  /**
   * Save the current state of the tree
   *
   * @return  void
   */
  onSave() {
    const {
      form,
      workguide,
      workguideLegacyActions
    } = this.props;

    const updated = flow(
      (data) => {
        return has(data, 'workflow')
          ? data
          : update(data, { workflow: { $set: {} } });
      },
      (data) => update(data, {
        workflow: {
          nodes: { $set: get(form, 'data.nodes', []) }
        }
      })
    )(workguide);

    workguideLegacyActions.setData({ key: 'workguide', value: updated });
    workguideLegacyActions.updateWorkguideRequest(updated);
  }

  /**
   * Create a new node with a random id
   *
   * @return  void
   */
  onNodeAdd() {
    const { workguideActions } = this.props;

    const id = `nd${last(v4().split('-'))}`;
    const node = {
      id,
      type: WORKFLOW_TREE_NODE,
      title: {
        de: id,
        fr: id,
        en: id,
        it: id
      }
    };

    this.nodes.add(node);
    workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());
  }

  /**
   * Update the given node in list
   *
   * @param   {Object}  node  Node to update
   *
   * @return  void
   */
  onNodeChange({ node }) {
    const { workguideActions } = this.props;

    this.nodes.replace(node);
    workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());

    // Add a small delay and call forceUpdate to make sure the connection get redrawn after the node was rerendered
    // setTimeout(() => this.forceUpdate(), 20);
  }

  /**
   * Handle node drop and calculate new position
   *
   * @param   {Object}  item     Drag item
   * @param   {Object}  monitor  Drag n' Drop monitor
   *
   * @return  void
   */
  onNodeDrop(item, monitor) {
    const { positions } = this.state;

    const { x, y } = monitor.getDifferenceFromInitialOffset();
    const position = has(positions, item.id)
      ? { x: get(positions, `${item.id}.x`) + x, y: get(positions, `${item.id}.y`) + y }
      : { x, y };

    const updated = update(positions, {
      [item.id]: { $set: position }
    });

    this.setState({ positions: updated });
  }

  /**
   * Duplicate the given node
   *
   * @param {Object} node Node to duplicate
   *
   * @return  void
   */
  onNodeDuplicate({ node }) {
    const { workguideActions } = this.props;

    this.nodes.duplicate(node);
    workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());
  }

  /**
   * Remove the given node
   *
   * @param {Object} node Node to remove
   *
   * @return  void
   */
  onNodeRemove({ node }) {
    const { workguideActions } = this.props;

    this.nodes.remove(node);
    workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());
  }

  /**
   * Start connecting o node to another
   *
   * @param   {Object}  config  Node config
   *
   * @return  void
   */
  onConnectStart({ node }) {
    this.setState({ connecting: true, connectionSource: node });
  }

  /**
   * End connecting a node. Set the given end node as child of the start node
   *
   * @return  void
   */
  onConnectEnd({ node }) {
    const {
      connecting,
      connectionSource
    } = this.state;
    const { workguideActions } = this.props;

    if (connecting && !isNil(node) && !get(connectionSource, 'connections', []).find((c) => c.target === node.id)) {
      this.nodes.connect({ source: connectionSource.id, target: node.id });
      workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());
    }

    this.setState({
      connecting: false,
      connectionSource: undefined
    });
  }

  /**
   * Handle connection change
   *
   * @param   {Object}  connection  Connection that changed
   *
   * @return  void
   */
  onConnectionChange({ connection }) {
    const { workguideActions } = this.props;

    const { source, target, ...rest } = connection;

    this.nodes
      .disconnect({ source, target })
      .connect({ source, target, ...rest });

    workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());
  }

  /**
   * Remove the connection between source and target
   *
   * @param   {Object}  connection  Connection to remove
   *
   * @return  void
   */
  onConnectionRemove({ connection }) {
    const { workguideActions } = this.props;

    this.nodes.disconnect({ source: connection.source, target: connection.target });
    workguideActions.workflowTreeConfigSetValue('nodes', this.nodes.getNodes());
  }

  /**
   * Rearrange the nodes on the board
   *
   * @return  {[type]}  [return description]
   */
  onRearrange() {
    this.setState({ rearranging: true }, () => {
      const { height, width } = document.getElementById('workflow-tree-board').getBoundingClientRect();
      const positions = this.nodes
        .rearrange({ containerHeight: height, containerWidth: width })
        .getPositions();

      this.setState({ positions, rearranging: false });
    });
  }

  /**
   * Validate current nodes
   *
   * @return  {Object} result Validation result
   */
  validate() {
    const { form } = this.props;

    const nd = {
      validations: {
        nodes: {
          type: 'array',
          required: true,
          validations: NodeValidationDefinition.validations
        }
      }
    };

    const cd = {
      validations: {
        nodes: {
          type: 'array',
          required: false,
          validations: {
            connections: {
              type: 'array',
              required: false,
              validations: ConnectionValidationDefinition.validations
            }
          }
        }
      }
    };

    const validator = getValidator();

    const nr = validator.validate(nd, get(form, 'data', {}));
    const cr = validator.validate(cd, get(form, 'data', {}));

    return {
      nodes: get(nr, 'nodes', []),
      connections: get(cr, 'nodes', [])
    };
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      connecting,
      connectionSource,
      positions
    } = this.state;
    const {
      language,
      consultants,
      form,
      workguide
    } = this.props;
    const validations = this.validate();

    if (!isEmpty(validations)) {
      /* eslint-disable */
      console.debug('Current tree is not valid!');
      console.debug('Configuration: ', get(form, 'data'));
      console.debug('Validation result: ', validations);
      /* eslint-enable */
    }

    const nodes = this.nodes.getNodes();
    const hasChanges = !isEqual(get(form, 'data'), get(workguide, 'workflow'));
    const children = nodes.map((node, index) => {
      return (
        <Node
          key={node.id}
          connecting={connecting}
          connectionStart={connectionSource}
          consultants={consultants}
          language={language}
          node={node}
          onChange={this.onNodeChange}
          onConnectEnd={this.onConnectEnd}
          onConnectStart={this.onConnectStart}
          onConnectionChange={this.onConnectionChange}
          onConnectionRemove={this.onConnectionRemove}
          onDuplicate={this.onNodeDuplicate}
          onRemove={this.onNodeRemove}
          position={get(positions, node.id)}
          validations={get(validations, `nodes.${index}`)}
          connectionValidations={get(validations, `connections.${index}.connections`)}
          workguide={workguide}
        />
      );
    });

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="workflow-tree">
          <div className="workflow-tree--toolbar">
            <div
              className="mdi mdi-plus-box-outline pointer"
              onClick={this.onNodeAdd}
            />

            <div className="mdi mdi-magnify-plus-outline" />
            <div className="mdi mdi-magnify-minus-outline" />
            <div
              className="mdi mdi-alien"
              onClick={this.onRearrange}
            />

            <div
              className={cl({
                mdi: 'true',
                pointer: true,
                'text-primary': isEmpty(validations.nodes) && isEmpty(validations.connections) && hasChanges,
                'text-warning': !isEmpty(validations.nodes) || !isEmpty(validations.connections),
                'mdi-content-save-alert-outline': !isEmpty(validations),
                'mdi-content-save-move-outline': hasChanges && isEmpty(validations),
                'mdi-content-save-outline': !hasChanges && isEmpty(validations)
              })}
              onClick={!hasChanges ? noop : this.onSave}
            />
          </div>

          <Board
            onClick={this.onConnectEnd}
            onDrop={this.onNodeDrop}
          >
            {children}
          </Board>
        </div>
      </DndProvider>
    );
  }
}

WorkflowTree.propTypes = {
  codeGroups: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  form: PropTypes.object,
  language: PropTypes.string,
  requesting: PropTypes.bool,
  workguide: PropTypes.object.isRequired,
  workguideActions: PropTypes.object.isRequired,
  workguideLegacyActions: PropTypes.object.isRequired,
  workguides: PropTypes.array
};

WorkflowTree.defaultProps = {
  codeGroups: [],
  codes: {},
  consultants: [],
  form: {},
  language: 'de',
  requesting: false,
  workguides: []
};

function mapStateToProps(state) {
  return {
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    consultants: state.workguide.consultants,
    form: state.workguide.workflowTreeConfig,
    language: state.login.language,
    requesting: state.workguide.requesting,
    workguide: state.workguide.workguide,
    workguides: state.workguide.workguides,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workguideActions: bindActionCreators(workguideActions, dispatch),
    workguideLegacyActions: bindActionCreators(workguideLegacyActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTree);
