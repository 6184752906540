import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cl from 'classnames';

import './Condition.css';
import Collapsible from '../../../../General/Collapsible';
import ConditionList from '../Condition/List';
import ConditionComponents from '../Condition/Components';

class WorkflowTreeFormEditNodeCondition extends React.Component {
  constructor(props) {
    super(props);

    this.onConditionChange = this.onConditionChange.bind(this);
  }

  /**
   * Handle conditions change
   *
   * @param   {Array}  condition  Initial and condition
   *
   * @return void
   */
  onConditionChange({ condition }) {
    const { onValueChange } = this.props;

    onValueChange('condition', condition);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      form,
      validations
    } = this.props;

    return (
      <Collapsible
        className={cl({
          'workflow-tree-form-edit-connection--collapsible': true,
          'workflow-tree-form-edit-connection--collapsible--invalid': has(validations, 'condition'),
          'workflow-tree-form-edit-connection--condition': true
        })}
        label={<FormattedMessage id="Workguide.Workflow.Form.EditNode.Title.Condition" />}
      >
        {/* First condition is always an and even if it contains only one child */}
        <ConditionList
          // We have to pass potential components to the list as importing them there would lead to a dependency cycle
          components={ConditionComponents}
          condition={get(form, 'data.condition')}
          isRemovable={false}
          onChange={this.onConditionChange}
          operation="and"
          showLabel={false}
          validations={get(validations, 'condition')}
        />

        <div style={{ paddingTop: '2em' }} />
      </Collapsible>
    );
  }
}

WorkflowTreeFormEditNodeCondition.propTypes = {
  form: PropTypes.object,
  onValueChange: PropTypes.func,
  validations: PropTypes.object
};

WorkflowTreeFormEditNodeCondition.defaultProps = {
  form: {},
  onValueChange: noop,
  validations: {}
};

export default WorkflowTreeFormEditNodeCondition;
